import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';

import Flex from '../../common/flex';
import theme from '../../theme';
import helperUtils from '../../utils/helper';

import formatUtils from './common/format';
import * as commonState from './common/state';
import * as graphTypeState from './graph-types/state';
import * as graphState from './graph/state';
import * as inputState from './inputs/state';
import * as outputState from './outputs/state';
import PrintChart from './print-chart';
import Radio from './radio';

const Container = styled.div`
  padding: 0.5rem 0;

  h1 {
    font-size: ${theme.size.heading};
    margin: 0.25rem 0;
  }

  h2 {
    border-bottom: 1px solid ${theme.color.steel};
    font-size: ${theme.size.subHeading};
    padding-bottom: 1rem;
  }
`;

const Disclaimer = styled.div`
  border-bottom: 1px solid ${theme.color.steel};
  line-height: 1.2rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
`;

const Section = styled(({ className, title, children }) => (
  <div className={className}>
    <h3>{title}:</h3>
    <div>{children}</div>
  </div>
))`
  margin: 1rem 0 2rem 0;

  h3 {
    font-size: ${theme.size.subHeading};
    margin-bottom: 0.5rem;
  }
`;

const Legend = styled(Flex.Row)`
  padding-bottom: 0.25rem;
`;

const LegendName = styled.div`
  margin-left: 0.5rem;
`;

const Chart = styled(({ className, ...props }) => (
  <div className={className}>
    <PrintChart {...props} />
  </div>
))`
  margin-top: 2rem;
  width: 65rem;

  // Hide this element for chart print otherwise 800+ pages are rendered
  .chartjs-size-monitor {
    display: none;
  }
`;

const DataTable = styled.table`
  border-bottom: 1px solid ${theme.color.steel};
  width: 100%;
`;

const DataYear = styled.th`
  border-top: 1px solid ${theme.color.steel};
  font-weight: ${theme.weight.medium};
  padding: 0.5rem;
  width: 10rem;
`;

const DataColor = styled.td`
  border-bottom: 0.5rem solid ${({ color }) => color};
`;

const DataValue = styled.td`
  border-top: 1px solid ${theme.color.steel};
  padding: 0.5rem;
`;

const InputTable = styled.table`
  border-bottom: 1px solid ${theme.color.steel};
  margin-bottom: 1rem;
  width: 100%;
`;

const InputScenario = styled.th`
  border-top: 1px solid ${theme.color.steel};
  font-weight: ${theme.weight.medium};
  padding: 0.5rem;
`;

const InputHeader = styled.th`
  border-top: 1px solid ${theme.color.steel};
  border-left: 1px solid ${theme.color.steel};
  font-weight: ${theme.weight.medium};
  padding: 0.5rem;
  vertical-align: top;
`;

const InputColor = styled.td`
  border-right: 0.5rem solid ${({ color }) => color};
`;

const InputYear = styled.td`
  border-top: 1px solid ${theme.color.steel};
  border-right: 1px solid ${theme.color.steel};
  padding: 0.5rem;
`;

const InputValue = styled.td`
  border-top: 1px solid ${theme.color.steel};
  padding: 0.5rem;
`;

const Print: React.FC = () => {
  const graphType = useObservable(() => graphTypeState.selectedId$, null);

  const dataYears = useObservable(() => graphState.years$, []);
  const dataValues = useObservable(() => graphState.data$, []);

  const inputYears = useObservable(() => inputState.years$, []);
  const inputTypes = useObservable(() => inputState.inputTypes$, []);
  const inputValues = useObservable(() => inputState.inputs$, []);

  const model = useObservable(
    () => commonState.models$.pipe(map(models => models.find(({ selected }) => selected))),
    null
  );

  const outputType = useObservable(
    () =>
      outputState.outputTypes$.pipe(
        map(outputTypes => outputTypes.find(({ selected }) => selected))
      ),
    null
  );

  // Work out which scenarios to show
  let scenarios = useObservable(() => inputState.scenarios$, []);
  if (graphType === 'multi') {
    scenarios = scenarios.filter(({ selected }) => selected);
  }

  // Only render if the data is available
  if (!model || !outputType) {
    return null;
  }

  return (
    <Container>
      <h1>{model && model.name}</h1>
      <h2>{outputType && outputType.name}</h2>
      <Disclaimer>
        The purpose of a projection model is to provide a rough projection of the Plan’s financial
        health under various scenarios. Projection results reflect possible outcomes based on
        projection inputs and assumptions used. The Plan’s actual results will differ from those
        projected to the extent actual plan provisions, assumptions, and emerging experience differs
        from the projection inputs. Projections should not be relied upon for any purpose without
        review by your Milliman consultant.
      </Disclaimer>
      <Section title={graphType === 'multi' ? 'Outputs' : 'Scenarios'}>
        {dataValues.length === 0 && <div>No {graphType === 'multi' ? 'outputs' : 'scenarios'}</div>}
        {dataValues.map(scenario => (
          <Legend key={scenario.id} align="middle">
            <Flex.Item>
              <Radio color={scenario.color} enabled={true} />
            </Flex.Item>
            <Flex.Item>
              <LegendName>{scenario.name}</LegendName>
            </Flex.Item>
          </Legend>
        ))}
        <Chart outputType={outputType} graphType={graphType} years={dataYears} data={dataValues} />
      </Section>
      <Section title="Data Table">
        {dataValues.length === 0 && <div>No data</div>}
        {dataValues.length > 0 && (
          <DataTable>
            <thead>
              <tr>
                <td></td>
                {dataValues.map(scenario => (
                  <DataColor key={scenario.id} color={scenario.color} />
                ))}
              </tr>
            </thead>
            <tbody>
              {dataYears.map(year => (
                <tr key={year}>
                  <DataYear>{year}</DataYear>
                  {dataValues.map(scenario =>
                    scenario.values
                      .filter(item => item.year === year)
                      .map((item, index) => {
                        const value = formatUtils.format(
                          outputType.formatType,
                          item.value,
                          outputType.div,
                          outputType.decimalPlaces
                        );

                        return <DataValue key={index}>{value}</DataValue>;
                      })
                  )}
                </tr>
              ))}
            </tbody>
          </DataTable>
        )}
      </Section>
      <Section title="Input Tables">
        {scenarios.length === 0 && <div>No scenarios</div>}
        {scenarios.map(scenario => {
          const scenarioInputs = inputValues.filter(input => input.scenario === scenario.id);
          return (
            <InputTable key={scenario.id}>
              <thead>
                <tr>
                  <InputColor color={scenario.color} />
                  <InputScenario colSpan={inputTypes.length + 1}>{scenario.name}</InputScenario>
                </tr>
                <tr>
                  <InputColor color={scenario.color} />
                  <InputHeader>Year</InputHeader>
                  {inputTypes.map(inputType => (
                    <InputHeader key={inputType.id}>{inputType.name}</InputHeader>
                  ))}
                </tr>
                {inputYears.length === 0 && (
                  <tr>
                    <InputColor color={scenario.color} />
                    <InputValue colSpan={inputTypes.length + 1}>No data</InputValue>
                  </tr>
                )}
              </thead>
              <tbody>
                {inputYears.map(year => (
                  <tr key={year}>
                    <InputColor color={scenario.color} />
                    <InputYear>{year}</InputYear>
                    {inputTypes.map(inputType => {
                      const input = scenarioInputs.find(
                        current => helperUtils.normalize(current.name) === inputType.id
                      );

                      // Find the value for year/input type match
                      const match = input?.values.find(value => value.year === year);
                      const value = formatUtils.format(
                        inputType.formatType,
                        match ? match.value : null,
                        1,
                        inputType.decimalPlaces
                      );

                      return <InputValue key={inputType.id}>{value}</InputValue>;
                    })}
                  </tr>
                ))}
              </tbody>
            </InputTable>
          );
        })}
      </Section>
    </Container>
  );
};

export default Print;
